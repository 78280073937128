<template>
  <div class="box-company-info">
    <div>修改密码</div>
    <div class="box-group">
      <!-- OldPassword -->
      <label for="password" class="control-label">当前密码 </label>
      <div>
        <input v-model="changeForm.password" type="text" class="form-control" id="password" @blur="checkPwd"
               :placeholder="placeholder.password"
               :class="{ error : checkField.password }">
        <span class="error-info">
          <span>{{ checkField.password }}</span>
        </span>
      </div>
    </div>
    <div class="box-group">
      <!-- NewPassword -->
      <label for="newpassword" class="control-label">新密码 </label>
      <div>
        <input v-model="changeForm.newpassword" type="password" class="form-control" id="newpassword"
               :placeholder="placeholder.newpassword"
               @blur="checkNewpwd" :class="{ error : checkField.newpassword }">
        <div class="hint-info">
          <div class="status-point" style="background-color: #FFB22D"/>
          <div>用户名由6-20个英文字母或数字组成</div>
        </div>
        <div class="hint-info">
          <div class="status-point" style="background-color: #00CF0F"/>
          <div>只允许使用字母和数字</div>
        </div>
        <span class="error-info">
          <span style="display: inline-block;width: 492px">{{ checkField.newpassword }}</span>
        </span>
      </div>
    </div>
    <div class="box-group">
      <!-- renewpassword -->
      <label for="renewpassword" class="control-label">重新输入新的密码 </label>
      <div>
        <input v-model="changeForm.renewpassword" type="password" class="form-control" id="renewpassword"
               :placeholder="placeholder.renewpassword"
               @blur="checkPwdmatch" :class="{ error : checkField.renewpassword }">
        <span class="error-info">
          <span>{{ checkField.renewpassword }}</span>
        </span>
      </div>
    </div>
    <div class="box-group" style="margin-top: 30px">
      <label/>
      <button type="button" class="btn btn-one" style="margin-left: 20px" @click.prevent="changePassowrd">保存</button>
    </div>

    <common-dialog id="changePwdDialog" :message="dialogmsg"/>
  </div>
</template>

<script>
import CommonDialog from '@/components/cn/CommonDialog'
import { getLocalStore } from '@/utils/common'

export default {
  components: {
    CommonDialog
  },
  name: 'CnChangePwd',
  data () {
    return {
      dialogmsg: '',
      changeForm: {
        userid: '',
        password: '',
        newpassword: '',
        renewpassword: ''
      },
      checkField: {
        password: '',
        newpassword: '',
        renewpassword: ''
      },
      placeholder: {
        password: '输入当前密码',
        newpassword: '输入你的新密码',
        renewpassword: '重新输入新密码'
      }
    }
  },
  computed: {},
  mounted () {
  },
  methods: {
    checkPwd () {
      if (!this.changeForm.password || !this.changeForm.password.replace(/\s*/g, '')) {
        this.checkField.password = '当前密码不能为空'
        return false
      } else {
        this.checkField.password = ''
        return true
      }
    },
    checkNewpwd () {
      if (this.changeForm.renewpassword) {
        this.checkPwdmatch()
      }
      if (!this.changeForm.newpassword || !this.changeForm.newpassword.replace(/\s*/g, '')) {
        this.checkField.newpassword = '新密码不能为空'
        return false
      } else if (!/^[a-zA-Z0-9]{6,20}$/.test(this.changeForm.newpassword)) {
        this.checkField.newpassword = '新密码长度不能超过6 - 20个字符, \r 只允许使用字母和数字'
        return false
      } else if (this.changeForm.newpassword === this.changeForm.password) {
        this.checkField.newpassword = '新密码不能与原密码相同'
        return false
      } else {
        this.checkField.newpassword = ''
        return true
      }
    },
    checkPwdmatch () {
      if (!this.changeForm.renewpassword || !this.changeForm.renewpassword.replace(/\s*/g, '')) {
        this.checkField.renewpassword = '请重新输入新密码'
        return false
      } else if (this.changeForm.newpassword !== this.changeForm.renewpassword) {
        this.checkField.renewpassword = '两次输入的密码不匹配'
        return false
      } else {
        this.checkField.renewpassword = ''
        return true
      }
    },
    checkForm () {
      return this.checkPwd() && this.checkNewpwd() && this.checkPwdmatch()
    },
    changePassowrd () {
      if (this.checkForm()) {
        this.$store.dispatch('SetLoadingStatus', true)
        this.changeForm.userid = getLocalStore().UserID
        this.$store.dispatch('ChangePassword', this.changeForm).then(() => {
          this.dialogmsg = 'savedsuccessfully'
          this.$store.dispatch('SetLoadingStatus', false)
          this.$bvModal.show('changePwdDialog')
        }).catch((err) => {
          if (err.ErrorCode === 6011) {
            this.dialogmsg = 'wrongpwd'
            this.$bvModal.show('changePwdDialog')
            this.$store.dispatch('SetLoadingStatus', false)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

$cell-margin-right: 20px;

.box-company-info {
  width: 963px;
  height: 534px;
  background-color: #ffffff;
  border-radius: 20px;
  color: #535353;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 30px  0 20px 30px;
}

.box-company-info > div:first-child {
  margin: 40px 0 20px 0;
  font-size: 20px;
  font-weight: bold;
}

.box-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
}

.box-group > label {
  width: 200px;
  font-size: 16px;
  text-align: right;
}

input {
  width: 488px;
  height: 35px;
  margin-left: $cell-margin-right;
  background-color: #EDEDED;
}

select {
  width: 160px;
}

.checkbox-style > input {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #707070;
  margin-right: 5px;
  margin-left: 0;
}

.btn-one {
  width: 492px;
  height: 35px;
  border-radius: 8px;
  background-color: #4285F4;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.form-control {
  background-color: #EDEDED;
}

.error-info {
  margin-left: $cell-margin-right;
}

.hint-info {
  color: #CCCCCC;
  font-size: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: $cell-margin-right;
}

.status-point {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fe5555;
  margin-right: 5px;
}

.hint-info > img {
  width: 6px;
  height: 6px;
  margin-right: 5px;
}

/*占位符修改*/
input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 10px;
  color: #CCCCCC;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 10px;
  color: #CCCCCC;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-size: 10px;
  color: #CCCCCC;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-size: 10px;
  color: #CCCCCC;
}
</style>
