<template>
  <div class="box-items">
    <div class="box-body">
      <div class="box-info" style="position: relative;">
        <my-upload field="img"
                   @crop-success="cropSuccess"
                   :modelValue.sync="show"
                   :width="300"
                   :height="300"
                   :no-square="true"
                   lang-type="zh"
                   :withCredentials="true"
                   img-format="png"/>
        <img class="hand"  @click="toggleShow" :src="getAvator(userinfo.Avator)" alt="">
        <img v-if="userinfo.ChargeState===3" class="vip" src="@/assets/image/cn/vip.png">
        <div class="box-name">{{ this.$store.getters.name }}</div>
        <div class="h-hvc mt5">
          <p style="font-size: 10px; color: #014479; max-width: 120px;">{{ userinfo.CompanyName }}</p>
        </div>
        <div style="font-size: 10px; color: #014479; max-width: 140px;display: flex">
          <div class="badge" v-if="userinfo.ChargeState===3">{{ userinfo.MemberType }}</div>
          <p v-if="userinfo.ChargeState===3">
            <span style="color: #5f5f5f;">至</span>
            <span style="color: #4285F4;">{{ getDate(userinfo.ChargeExpireDate) }}</span>
          </p>
        </div>
        <div style="font-size: 10px; color: #535353; width: 180px;display: flex;justify-content: space-between;">
          <p>注册时间</p>
          <p style="margin-left: 10px;color: #4285F4">{{ getDate(userinfo.CompanyRegTime) }}</p>
        </div>
        <div style="font-size: 10px; color: #535353; width: 180px;display: flex;justify-content: space-between;margin-bottom: 20px">
          <p>注册天数</p>
          <p style="margin-left: 10px;color: #4285F4">{{ userinfo.RegDay }}天</p>
        </div>

        <div class="box-item hand" v-for="(item,index) in items" :key="index" :class="{ active: current === index }" @click="handleTabItem(index)">
          <div>
            <img v-if="current !== index" :src="item.icon[0]" alt="">
            <img v-if="current === index" :src="item.icon[1]" alt="">
          </div>
          {{ item.label }}
        </div>
      </div>
      <company-info v-if="current === 0"/>
      <change-pwd v-if="current === 6"/>
      <favorite v-if="current === 1"/>
    </div>
  </div>
</template>

<script>
import MyUpload from 'vue-image-crop-upload'
import CompanyInfo from './components/company-info'
import ChangePwd from './components/change-pwd'
import Favorite from './components/favorite'
import { getLocalStore } from '@/utils/common'

export default {
  name: 'Account',
  components: {
    MyUpload,
    CompanyInfo,
    ChangePwd,
    Favorite
  },
  data () {
    return {
      current: 0,
      userinfo: {},
      show: false,
      items: [
        {
          label: '我的公司信息',
          icon: [require('@/assets/image/cn/icon/icon1.png'), require('@/assets/image/cn/icon/icon2.png')]
        },
        {
          label: '我的收藏',
          icon: [require('@/assets/image/cn/icon/icon3.png'), require('@/assets/image/cn/icon/icon4.png')]
        }, {
          label: '必孚肉集(App专享)',
          icon: [require('@/assets/image/cn/icon/icon5.png')]
        }, {
          label: '必孚工厂-收藏(App专享)',
          icon: [require('@/assets/image/cn/icon/icon6.png')]
        },
        /*
        {
          label: '必孚工厂-收件箱(App专享)',
          icon: [require('@/assets/image/cn/icon/icon7.png')]
        },
        {
          label: '必孚工厂-发件箱(App专享)',
          icon: [require('@/assets/image/cn/icon/icon8.png')]
        },
        */
        {
          label: '期货代理服务(App专享)',
          icon: [require('@/assets/image/cn/icon/icon9.png')]
        },
        {
          label: '现货代理采销(App专享)',
          icon: [require('@/assets/image/cn/icon/icon10.png')]
        },
        {
          label: '修改密码',
          icon: [require('@/assets/image/cn/icon/icon11.png'), require('@/assets/image/cn/icon/icon12.png')]
        }
      ]
    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods: {
    getName (str) {
      if (str) {
        return str.substring(0, 16)
      }
    },
    getDate (date) {
      return this.dayjs(date).format('YYYY-MM-DD')
    },
    handleTabItem (index) {
      if (index === 0 || index === 1 || index === 6) {
        this.current = index
      }
    },
    getUserInfo () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetFrontUserInfoByUserID', {
        UserID: getLocalStore().UserID
      }).then(() => {
        this.userinfo = this.$store.getters.userow
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch(() => {
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getAvator (path) {
      return path ? process.env.VUE_APP_STATIC + path : require('@/assets/image/login/sidebar-default.png')
    },

    toggleShow () {
      this.show = !this.show
    },
    cropSuccess (imgDataUrl) {
      const file = this.dataURLtoFile(imgDataUrl, 'portrait.png')
      this.uploadImg(file)
    },
    // 上传头像
    uploadImg (file) {
      this.$store.dispatch('UploadAvator', {
        Files: file,
        UserID: getLocalStore().UserID
      }).then(() => {
        this.getUserInfo()
      }).catch((err) => {
        console.log(JSON.stringify(err))
      })
    },
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      // 转换成file对象
      return new File([u8arr], filename, { type: mime })
    }
  }
}
</script>

<style scoped>
.box-items{
  background-color: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-body{
  width: 1200px;
  display: flex;
  justify-content: space-between;
}
.box-info{
  width: 210px;
  min-height: 836px;
  max-height: 1100px;
  /*border-radius: 20px;*/
  box-shadow: 1px 1px 1px #e1e1e1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
}
.box-info > img{
  width: 71px;
  height: 71px;
  margin-top: 40px;
  border-radius: 71px;
}
.box-name{
  font-size: 14px;
  font-weight: bold;
  color: #014479;
  margin: 10px 0 5px 0;
}
.box-item{
  width: 210px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #F5F5F5;
  color: #535353;
  font-size: 14px;
}

.box-item > div{
  width: 35px;
  text-align: center;
}
.box-item > div > img{
  width: 16px;
  height: 16px;
}

.badge {
  color: #FFA200;
}

.vip {
  width: 19px!important;
  height: 18px!important;
  position: absolute;
  top: 55px;
  right: 75px;
}
.active{
  color: #ffffff;
  background-color: #014479;
}
</style>
