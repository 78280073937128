<template>
  <div class="section-card-box hand" @click="handleClickItem">
    <div class="img-box">
      <img :src="other.NewsTitlePic || data.NewsTitlePic">
      <img v-show="Object.keys(other).length > 0 ? !!other.VideoLink : !!data.VideoLink" src="@/assets/image/btcinfo/video.png">
    </div>
    <div class="text-box">
      <h6 style="height: 57px; color: #014479;font-size: 14px">{{ other.NewsTitle || data.NewsTitle }}</h6>
      <div class="bottom-box">
          <div class="time">
            <div class="btc-triangle triangle-point-right tc-1" />
            <span>{{ other.NewsDateTime || data.NewsDateTime }}</span>
          </div>
        <div class="card-flag">
          <img v-for="(item, index) in langs" :key="index" :src="item.icon" :alt="item.lang" class="hand" @click.stop="handleChangeLang(item.lang)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CnFavoriteExpress',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isEnExpire: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      other: {},
      langIcon: [
        { lang: 'en', icon: require('@/assets/image/btcinfo/american.png') },
        { lang: 'spanish', icon: require('@/assets/image/btcinfo/spanish.png') },
        { lang: 'portuguese', icon: require('@/assets/image/btcinfo/portuguese.png') }
      ]
    }
  },
  computed: {
    langs () {
      const newArr = [...this.data.Langs]
      if (newArr.length !== 0) {
        newArr.unshift('en')
      }
      return this.langIcon.filter(v => {
        return newArr.includes(v.lang)
      })
    },
    articles () {
      return this.data.Recommendation
    }
  },
  methods: {
    handleClickItem () {
      this.$store.commit('SET_NEWSID', this.data.NewsID)
      // this.$router.push(`/BTCInfo/Article/${this.other.NewsID || this.data.NewsID}`)
      const path = `/cn/BTCInfo/Article/${this.other.NewsID || this.data.NewsID}`
      const routeData = this.$router.resolve({ path: path, query: { rid: this.data.NewsID } })
      const open = window.open(routeData.href, '_blank')
      if (open === null || typeof (open) === 'undefined') {
        this.$router.push(path)
      }
    },
    handleChangeLang (lang) {
      if (lang === 'en') {
        this.other = {}
      } else {
        this.other = this.articles.filter(v => {
          return v.NewsLanguageCode === lang
        })[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.section-card-box {
  width: 580px;
  height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-card-box > .img-box {
  position: relative;
}
.section-card-box > .img-box > img:first-child {
  width: 164px;
  height: 99px;
  border-radius: 4px;
}
.section-card-box > .img-box > img:last-child {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.section-card-box > .text-box {
  display: flex;
  flex-direction: column;
  min-width: 416px;
  padding: 8px;
}

.bottom-box {
  display: flex;
  justify-content: space-between;
}

.card-flag {
  display: flex;
  align-items: center;
}
.card-flag > img {
  height: 20px
}
.card-flag > img:last-child {
  margin-left: 10px;
}

.time {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
</style>
